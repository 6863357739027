.account-view {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
}

.account-table {
    margin-top: 20px;
}

.account-cell {
    font-weight: bold;
    color: #666;
}

.ui.card {
    box-shadow: 0 0 0 0;
}


