.center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.customImageSize {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover; /* To make sure image is resized to fill its container while maintaining its aspect ratio */
}