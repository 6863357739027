.overview {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
}

.account-table {
    background-color: #f5f5f5;;
}

.account-cell {
    font-weight: bold;
    color: #666;
}

.ui.card {
    box-shadow: 0 0 0 0;
}

.total {
    border-top: 4px double !important;

}

